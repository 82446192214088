.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 1000;
  background-color: #000000a3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup,
.popup_interaction {
  position: relative;
  display: flex;
  max-width: 25%;
}

.interaction_image {
  width: 100%;
  height: auto;
  object-fit: fill;
  cursor: pointer;
  scale: 1;
}

.shaking {
  animation: shaking 3s ease-in-out infinite;
}

.scaling {
  animation: scaling 0.4s ease-in-out;
}

.campaign_img {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
}

.close_icon_popup,
.close_icon_interaction {
  position: absolute;
  color: #000;
  right: 10px;
  top: 10px;
  font-size: 1rem;
  background-color: #dfc8e7;
  display: flex;
  padding: 2%;
  border-radius: 50%;
  cursor: pointer;
}

.close_icon_interaction {
  font-size: 0.7rem;
}

.handy {
  position: absolute;
  top: 75%;
  right: 15%;
  width: 40px;
  height: auto;
  z-index: 1000;
  animation: clicker 0.8s ease-in-out infinite;
}

.hiding {
  animation: hiding 1s ease-in-out;
}

@keyframes shaking {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  20% {
    -webkit-transform: rotateZ(0deg);
  }

  /* 1 */
  24% {
    -webkit-transform: rotateZ(2deg) translateY(-2%);
  }
  27% {
    -webkit-transform: rotateZ(0deg);
  }
  28% {
    -webkit-transform: rotateZ(-2deg) translateY(-2%);
  }
  29% {
    -webkit-transform: rotateZ(0deg);
  }
  30% {
    -webkit-transform: rotateZ(2deg) translateY(-2%);
  }
  31% {
    -webkit-transform: rotateZ(0deg);
  }
  32% {
    -webkit-transform: rotateZ(-2deg) translateY(-2%);
  }
  33% {
    -webkit-transform: rotateZ(0deg);
  }

  /* 2 */
  49% {
    -webkit-transform: rotateZ(0deg);
  }
  50% {
    -webkit-transform: rotateZ(2deg) translateY(-2%);
  }
  53% {
    -webkit-transform: rotateZ(0deg);
  }
  54% {
    -webkit-transform: rotateZ(-2deg) translateY(-2%);
  }
  55% {
    -webkit-transform: rotateZ(0deg);
  }
  56% {
    -webkit-transform: rotateZ(2deg) translateY(-2%);
  }
  57% {
    -webkit-transform: rotateZ(0deg);
  }
  58% {
    -webkit-transform: rotateZ(-2deg) translateY(-2%);
  }
  59% {
    -webkit-transform: rotateZ(0deg);
  }

  /* 3 */

  65% {
    -webkit-transform: rotateZ(0deg);
  }
  66% {
    -webkit-transform: rotateZ(2deg) translateY(-2%);
  }
  69% {
    -webkit-transform: rotateZ(0deg);
  }
  70% {
    -webkit-transform: rotateZ(-2deg) translateY(-2%);
  }
  73% {
    -webkit-transform: rotateZ(0deg);
  }
  74% {
    -webkit-transform: rotateZ(2deg) translateY(-2%);
  }
  77% {
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes scaling {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes hiding {
  0% {
    opacity: 0;
    scale: 0.7;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes clicker {
  0% {
    transform: rotate(0deg) translateX(0px);
  }
  50% {
    transform: rotate(-20deg) translateX(-15px);
  }
}

/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
  .popup,
  .popup_interaction {
    max-width: 50%;
  }
}
@media only screen and (max-width: 650px) {
  .popup,
  .popup_interaction {
    max-width: 80%;
  }

  .close_icon_popup {
    font-size: 0.9rem;
  }

  .close_icon_interaction {
    font-size: 0.7rem;
  }

  .handy {
    top: 70%;
    right: 13%;
    width: 30px;
    height: auto;
  }

  .interaction_image {
    scale: 0.9;
  }
}
